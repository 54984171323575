import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getLocationsByCabinetId = (
  id: TByCabinetIdLocationsRequest
): RequestPromise<TByCabinetIdLocationsResponse> => {
  return apiRequest.get(`/location/cabinet/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TByCabinetIdLocationsRequest = number;

export type TByCabinetIdLocationsResponse = TLocation[];
