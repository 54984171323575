import { createAsyncThunk } from "@reduxjs/toolkit";
import { locationAddActions } from ".";
import * as api from "api/locations";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const addLocation = createAsyncThunk<
  api.TAddLocationResponse,
  api.TAddLocationRequest
>("location/addLocation", (payload, { dispatch, rejectWithValue }) => {
  dispatch(locationAddActions.setLoading(true));
  dispatch(locationAddActions.setError(null));
  return api
    .addLocation(payload)
    .then(({ data }) => {
      dispatch(locationAddActions.setLocation(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(locationAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(locationAddActions.setLoading(false));
    });
});
