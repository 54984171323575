import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const updateLocation = (
  data: TUpdateLocationRequest
): RequestPromise<TUpdateLocationResponse> => {
  return apiRequest.post(`/location/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TUpdateLocationRequest = TLocation;

export type TUpdateLocationResponse = TLocation;
