import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationsTableSettingsActions } from ".";
import * as api from "api/accounts";
import { RequestError, TApplicationsTableSettings } from "types";
import { serializeError } from "serialize-error";
import { defaultApplicationsTableSettings } from "constants/settings";

export const getApplicationsTableSettings = createAsyncThunk(
  // <
  //   api.TAddAccountResponse,
  //   api.TAddAccountRequest
  // >
  "settings/applicationsTableGet",
  (payload, { dispatch, rejectWithValue }) => {
    const settings = localStorage.getItem("applicationsTableSettings");

    if (!settings) {
      localStorage.setItem(
        "applicationsTableSettings",
        JSON.stringify(defaultApplicationsTableSettings)
      );
      dispatch(
        applicationsTableSettingsActions.setSettings(
          defaultApplicationsTableSettings
        )
      );
    } else {
      dispatch(
        applicationsTableSettingsActions.setSettings(JSON.parse(settings))
      );
    }

    // dispatch(applicationsTableSettingsActions.setLoading(true));
    // dispatch(applicationsTableSettingsActions.setError(null));
    // return api
    //   .addAccount(payload)
    //   .then(({ data }) => {
    //     dispatch(applicationsTableSettingsActions.setSettings(data));
    //     return data;
    //   })
    //   .catch((err: RequestError) => {
    //     dispatch(applicationsTableSettingsActions.setError(err));
    //     return rejectWithValue(serializeError(err));
    //   })
    //   .finally(() => {
    //     dispatch(applicationsTableSettingsActions.setLoading(false));
    //   });
  }
);
export const saveApplicationsTableSettings = createAsyncThunk<
  void,
  TApplicationsTableSettings
>(
  "settings/applicationsTableSave",
  (payload, { dispatch, rejectWithValue }) => {
    localStorage.setItem("applicationsTableSettings", JSON.stringify(payload));

    // dispatch(applicationsTableSettingsActions.setLoading(true));
    // dispatch(applicationsTableSettingsActions.setError(null));
    // return api
    //   .addAccount(payload)
    //   .then(({ data }) => {
    //     dispatch(applicationsTableSettingsActions.setSettings(data));
    //     return data;
    //   })
    //   .catch((err: RequestError) => {
    //     dispatch(applicationsTableSettingsActions.setError(err));
    //     return rejectWithValue(serializeError(err));
    //   })
    //   .finally(() => {
    //     dispatch(applicationsTableSettingsActions.setLoading(false));
    //   });
  }
);
