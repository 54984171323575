import React, { FC, useCallback, useMemo, useState } from "react";
import { Button, ButtonProps, Checkbox, Drawer } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "store/store";
import { TOffersTableSettings } from "types";
import { CheckboxOptionType } from "antd/es/checkbox";
import {
  offersTableSettingsActions,
  offersTableSettingsSelectors
} from "store/settings/offersTable";
import { saveOffersTableSettings } from "store/settings/offersTable/thunk";

type TOffersTableSettingsProps = {
  size?: ButtonProps["size"];
};

export const OffersTableSettings: FC<TOffersTableSettingsProps> = ({
  size = "large"
}) => {
  const dispatch = useAppDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { settings } = useAppSelector(offersTableSettingsSelectors.getState);

  const options: CheckboxOptionType<keyof TOffersTableSettings>[] = useMemo(
    () => [
      { label: "Наименование", value: "offer_name" },
      { label: "Заявка", value: "application_name" },
      { label: "Статус", value: "status_id" },
      { label: "Юр. лицо", value: "seller_entity_name" },
      { label: "Создатель", value: "creator_name" },
      { label: "Позиций", value: "positions_length" },
      { label: "Стоимость", value: "price" },
      { label: "Дата поставки", value: "create_ts" },
      { label: "Дата создания", value: "update_ts" },
      { label: "Дата изменения", value: "delivary_plan_ts" },
      { label: "Регион", value: "offer_region_id" },
      { label: "Адрес доставки", value: "delivery" },
      { label: "Кол-во дней отложенного платежа", value: "postpayment" },
      { label: "Комментарий", value: "offer_comment" },
      { label: "Сообщения", value: "can_message" }
    ],
    []
  );

  const checkboxValue = useMemo(
    () =>
      Object.keys(settings)?.filter(
        (item) => settings[item as keyof TOffersTableSettings]
      ) as (keyof TOffersTableSettings)[],
    [settings]
  );

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    dispatch(saveOffersTableSettings(settings));
  }, [dispatch, settings]);

  const onChange = useCallback(
    (values: (keyof TOffersTableSettings)[]) => {
      if (values?.length) {
        const newSettings: TOffersTableSettings = values?.reduce(
          (acc, item) => {
            return { ...acc, [item]: true };
          },
          {}
        );
        dispatch(offersTableSettingsActions.setSettings(newSettings));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Button size={size} onClick={openDrawer} icon={<SettingOutlined />} />
      <Drawer
        title="Настройки таблицы предложений"
        open={isDrawerOpen}
        onClose={closeDrawer}
        destroyOnClose
      >
        <Checkbox.Group
          options={options}
          value={checkboxValue}
          onChange={onChange}
          style={{ display: "flex", flexDirection: "column" }}
        />
      </Drawer>
    </>
  );
};
