import { TApplicationsTableSettings } from "types";

export const defaultApplicationsTableSettings: TApplicationsTableSettings = {
  application_name: true,
  buyer_entity_name: true,
  project_name: true,
  creator_name: true,
  responsible_name: true,
  positions_length: true,
  can_message: true,
  application_creation_timestamp: false,
  application_modified_timestamp: false,
  application_region: false,
  delivery: false,
  postpayment: false,
  application_delivery_due_timestamp: true,
  application_comment: true
};
