import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersTableSettingsActions } from ".";
import * as api from "api/accounts";
import { RequestError, TOffersTableSettings } from "types";
import { serializeError } from "serialize-error";
import { defaultOffersTableSettings } from "constants/settings";

export const getOffersTableSettings = createAsyncThunk(
  // <
  //   api.TAddAccountResponse,
  //   api.TAddAccountRequest
  // >
  "settings/offersTableGet",
  (payload, { dispatch, rejectWithValue }) => {
    const settings = localStorage.getItem("offersTableSettings");

    if (!settings) {
      localStorage.setItem(
        "offersTableSettings",
        JSON.stringify(defaultOffersTableSettings)
      );
      dispatch(
        offersTableSettingsActions.setSettings(defaultOffersTableSettings)
      );
    } else {
      dispatch(offersTableSettingsActions.setSettings(JSON.parse(settings)));
    }

    // dispatch(offersTableSettingsActions.setLoading(true));
    // dispatch(offersTableSettingsActions.setError(null));
    // return api
    //   .addAccount(payload)
    //   .then(({ data }) => {
    //     dispatch(offersTableSettingsActions.setSettings(data));
    //     return data;
    //   })
    //   .catch((err: RequestError) => {
    //     dispatch(offersTableSettingsActions.setError(err));
    //     return rejectWithValue(serializeError(err));
    //   })
    //   .finally(() => {
    //     dispatch(offersTableSettingsActions.setLoading(false));
    //   });
  }
);
export const saveOffersTableSettings = createAsyncThunk<
  void,
  TOffersTableSettings
>("settings/offersTableSave", (payload, { dispatch, rejectWithValue }) => {
  localStorage.setItem("offersTableSettings", JSON.stringify(payload));

  // dispatch(offersTableSettingsActions.setLoading(true));
  // dispatch(offersTableSettingsActions.setError(null));
  // return api
  //   .addAccount(payload)
  //   .then(({ data }) => {
  //     dispatch(offersTableSettingsActions.setSettings(data));
  //     return data;
  //   })
  //   .catch((err: RequestError) => {
  //     dispatch(offersTableSettingsActions.setError(err));
  //     return rejectWithValue(serializeError(err));
  //   })
  //   .finally(() => {
  //     dispatch(offersTableSettingsActions.setLoading(false));
  //   });
});
