import { combineReducers } from "@reduxjs/toolkit";
import { locationAddReducer } from "./add";
import { locationUpdateReducer } from "./update";
import { locationsByEntityIdReducer } from "./byEntity";
import { locationByProjectIdReducer } from "./byProject";
import { locationsByCabinetIdReducer } from "./byCabinet";

export const locationsReducer = combineReducers({
  add: locationAddReducer,
  update: locationUpdateReducer,
  byEntityId: locationsByEntityIdReducer,
  byProjectId: locationByProjectIdReducer,
  byCabinetId: locationsByCabinetIdReducer
});
