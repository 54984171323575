import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationsTableSettingsState } from "./types";
import { RequestError, TApplicationsTableSettings } from "types";

const initialState: TApplicationsTableSettingsState = {
  isLoading: false,
  error: null,
  settings: {}
};

export const ApplicationsTableSettingsSlice = createSlice({
  name: "settings/applicationsTable",
  initialState,
  reducers: {
    clearState: () => initialState,
    setSettings: (
      state,
      { payload }: PayloadAction<TApplicationsTableSettings>
    ) => {
      state.settings = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const applicationsTableSettingsActions =
  ApplicationsTableSettingsSlice.actions;
export const applicationsTableSettingsReducer =
  ApplicationsTableSettingsSlice.reducer;

export * as applicationsTableSettingsSelectors from "./selectors";
