import React, { FC, useMemo } from "react";
import {
  Wrapper,
  DataBlock,
  DataTop,
  TitleBlock,
  CTABlock,
  OffersCountBlock,
  DataBottom,
  StagesBlock,
  ButtonsBlock,
  LinkButton
} from "./ApplicationHeader.styles";
import {
  LeftOutlined,
  EditOutlined,
  TagsFilled,
  DownOutlined,
  MailFilled
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  DescriptionsProps,
  Dropdown,
  MenuProps,
  Space,
  Typography
} from "antd";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";
import { toDisplayDate } from "utils/dates";
import { projectRegionIdMapper } from "utils/mappers";
import { ApplicationTimeline } from "../ApplicationTimeline/ApplicationTimeline";
import {
  ApplicationStatusTag,
  BasicTitle,
  DescriptionsCustom,
  TApplicationDataProps,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { ErrorAlert } from "components/common/old";
import { applicationUpdateStatusOptions } from "constants/options";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";

type TApplicationHeaderProps = Omit<
  TApplicationDataProps,
  "files" | "offers" | "getFiles" | "canEditFiles"
> & {
  onBuyerCompanyClick: () => void;
  onBuyerAccountClick: () => void;
};

export const ApplicationHeader: FC<TApplicationHeaderProps> = ({
  application,
  entity,
  project,
  // location,
  offersLength,
  onGoBack,
  onEdit,
  withEdit,
  onApprove,
  hasApprovements,
  approvementIsLoading,
  approvementError,

  withStatusChange,
  onUpdateStatus,
  statusIsLoading,
  statusError,

  onBuyerCompanyClick,
  onBuyerAccountClick,
  forSeller,
  withOffer,
  onAddOffer,
  previewOnly,
  onStepEdit,

  withMessages
}) => {
  const isXXL = useMediaQuery({ query: DEVICE.XXL });
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isMD = useMediaQuery({ query: DEVICE.MD });

  const { user } = useAppSelector(userSelectors.getState);

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      withStatusChange
        ? (
            applicationUpdateStatusOptions[
              application?.application_status_id
            ] || []
          ).map((status) => {
            return {
              key: status,
              label: <ApplicationStatusTag status_id={status} />
            };
          })
        : [],
    [application, withStatusChange]
  );

  const applicationData: DescriptionsProps["items"] = useMemo(
    () => [
      ...(!previewOnly
        ? [
            {
              key: "application_creation_datetime",
              label: "Дата создания",
              children: toDisplayDate(
                application?.application_creation_timestamp
              )
            }
          ]
        : []),
      {
        key: "application_delivery_due_timestamp",
        label: "Желаемая дата поставки",
        children: toDisplayDate(application?.application_delivery_due_timestamp)
      },
      {
        key: "entity_full_name",
        label: "Юр. лицо",
        children: (
          <LinkButton onClick={onBuyerCompanyClick} $accent>
            {entity?.full_name}
          </LinkButton>
        )
      },
      ...(!forSeller
        ? [
            {
              key: "project_name",
              label: "Проект",
              children: project?.project_name
            }
          ]
        : []),
      {
        key: "application_region_id",
        label: "Регион",
        children:
          (project && projectRegionIdMapper[project?.region_id]?.fullName) ||
          project?.region_id
      },
      {
        key: "is_need_delivery",
        label: "Необходима доставка",
        children: application?.is_need_delivery ? "Да" : "Нет"
      },
      ...(application?.is_need_delivery
        ? [
            {
              key: "location",
              label: "Адрес",
              children: application?.location?.address
            }
          ]
        : []),
      ...(application?.application_comment
        ? [
            {
              key: "application_comment",
              label: "Комментарий",
              children: application?.application_comment
            }
          ]
        : [])
    ],
    [application, entity, forSeller, onBuyerCompanyClick, previewOnly, project]
  );
  const paymentData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "buyer_account_number",
        label: "Счет",
        children: (
          <LinkButton onClick={onBuyerAccountClick}>
            {application?.buyer_account?.name}
          </LinkButton>
        )
      },
      {
        key: "is_postpayment",
        label: "Необходим отложенный платеж",
        children: application?.is_postpayment ? "Да" : "Нет"
      },
      ...(application?.is_postpayment
        ? [
            {
              key: "postpayment_days",
              label: "Кол-во дней отложенного платежа",
              children: `${application?.postpayment_days} дней`
            }
          ]
        : [])
    ],
    [application, onBuyerAccountClick]
  );

  return (
    <Wrapper>
      <DataBlock>
        <DataTop>
          {!previewOnly && (
            <TitleBlock>
              <BasicTitle>
                <Button
                  icon={<LeftOutlined />}
                  onClick={onGoBack}
                  type="text"
                />
                Заявка № {application?.application_id} -{" "}
                {application?.application_name}
              </BasicTitle>

              {!isMD && (withEdit || withMessages) && (
                <CTABlock>
                  {withEdit && (
                    <Button
                      onClick={onEdit}
                      icon={<EditOutlined />}
                      size={!isLG ? undefined : "large"}
                    >
                      Редактировать
                    </Button>
                  )}
                  {withMessages && (
                    <Link
                      to={
                        forSeller
                          ? `../../messages/${application?.application_id}/${user?.cabinet?.cabinet_id}`
                          : `messages`
                      }
                    >
                      <Button
                        icon={<MailFilled />}
                        size={!isLG ? undefined : "large"}
                      >
                        Сообщения
                      </Button>
                    </Link>
                  )}
                </CTABlock>
              )}

              {isLG &&
                (statusOptions?.length ? (
                  <>
                    {/* dropdown с цветами статусов */}
                    <Dropdown
                      menu={{ items: statusOptions, onClick: onUpdateStatus }}
                    >
                      <Button
                        loading={statusIsLoading}
                        style={{ marginRight: "auto" }}
                        size="small"
                        type="text"
                      >
                        <Space>
                          <ApplicationStatusTag
                            status_id={application?.application_status_id}
                          />
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>

                    <ErrorAlert error={statusError} />
                  </>
                ) : (
                  <ApplicationStatusTag
                    status_id={application?.application_status_id}
                    style={{ width: "fit-content" }}
                  />
                ))}

              {isLG && hasApprovements && (
                <>
                  <ButtonsBlock>
                    <Button
                      loading={approvementIsLoading}
                      onClick={() => onApprove?.(109)}
                    >
                      Согласовать
                    </Button>
                    <Button
                      onClick={() => onApprove?.(110)}
                      loading={approvementIsLoading}
                      danger
                    >
                      Отклонить
                    </Button>
                  </ButtonsBlock>
                  <ErrorAlert error={approvementError} />
                </>
              )}
              {isLG && forSeller && withOffer && (
                <ButtonsBlock>
                  <Button
                    onClick={onAddOffer}
                    icon={<TagsFilled />}
                    type="primary"
                  >
                    Составить предложение
                  </Button>
                </ButtonsBlock>
              )}

              {isLG && !forSeller && (
                <OffersCountBlock>
                  <Typography.Text type="secondary">
                    Всего предложений:
                  </Typography.Text>
                  <TypographyTitleNoMargin level={!isXXL ? 3 : 4}>
                    {offersLength || 0}
                  </TypographyTitleNoMargin>
                </OffersCountBlock>
              )}
            </TitleBlock>
          )}
          {!previewOnly && (
            <>
              {!isLG && hasApprovements && (
                <>
                  <ButtonsBlock>
                    <Button
                      loading={approvementIsLoading}
                      onClick={() => onApprove?.(109)}
                    >
                      Согласовать
                    </Button>
                    <Button
                      onClick={() => onApprove?.(110)}
                      loading={approvementIsLoading}
                      danger
                    >
                      Отклонить
                    </Button>
                  </ButtonsBlock>
                  <ErrorAlert error={approvementError} />
                </>
              )}
              {!isLG && forSeller && (
                <ButtonsBlock>
                  <Button
                    onClick={onAddOffer}
                    icon={<TagsFilled />}
                    type="primary"
                  >
                    Составить предложение
                  </Button>
                </ButtonsBlock>
              )}

              {!isLG && !forSeller && (
                <OffersCountBlock>
                  <Typography.Text type="secondary">
                    Всего предложений:
                  </Typography.Text>
                  <TypographyTitleNoMargin level={!isXXL ? 3 : 4}>
                    {offersLength || 0}
                  </TypographyTitleNoMargin>
                </OffersCountBlock>
              )}
              {isMD && (withEdit || withMessages) && (
                <CTABlock>
                  {withEdit && (
                    <Button
                      onClick={onEdit}
                      icon={<EditOutlined />}
                      size={!isLG ? undefined : "large"}
                    >
                      Редактировать
                    </Button>
                  )}
                  {withMessages && (
                    <Link
                      to={
                        forSeller
                          ? `../../messages/${application?.application_id}/${user?.cabinet?.cabinet_id}`
                          : `messages`
                      }
                    >
                      <Button
                        icon={<MailFilled />}
                        size={!isLG ? undefined : "large"}
                      >
                        Сообщения
                      </Button>
                    </Link>
                  )}
                </CTABlock>
              )}
            </>
          )}
          {isLG && (
            <Collapse
              ghost
              items={[
                {
                  label: "Этапы заявки",
                  children: (
                    <ApplicationTimeline
                      status_id={application?.application_status_id}
                    />
                  )
                }
              ]}
            />
          )}
        </DataTop>
        <DataBottom>
          {previewOnly && (
            <>
              <TypographyTitleNoMargin level={5}>
                <Button
                  icon={<EditOutlined onClick={() => onStepEdit?.(0)} />}
                />
                Параметры заявки
              </TypographyTitleNoMargin>
              <DescriptionsCustom
                items={[
                  {
                    key: "application_name",
                    label: "Наименование",
                    children: application?.application_name
                  }
                ]}
              />
            </>
          )}
          <DescriptionsCustom
            items={applicationData}
            title={"Параметры заявки"}
          />
          <DescriptionsCustom items={paymentData} title={"Оплата"} />
        </DataBottom>
      </DataBlock>
      {!isLG && (
        <StagesBlock>
          {!previewOnly &&
            (statusOptions?.length ? (
              <>
                <Dropdown
                  menu={{ items: statusOptions, onClick: onUpdateStatus }}
                >
                  <Button
                    loading={statusIsLoading}
                    style={{ marginLeft: "auto" }}
                    size="small"
                    type="text"
                  >
                    <Space>
                      <ApplicationStatusTag
                        status_id={application?.application_status_id}
                      />
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

                <ErrorAlert error={statusError} />
              </>
            ) : (
              <ApplicationStatusTag
                status_id={application?.application_status_id}
              />
            ))}

          <TypographyTitleNoMargin level={5}>
            Этапы заявки
          </TypographyTitleNoMargin>
          <ApplicationTimeline
            status_id={application?.application_status_id}
            mode="right"
          />
        </StagesBlock>
      )}
    </Wrapper>
  );
};
