import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popover,
  Row,
  Select
} from "antd";
import { applicationStatusIdOptions } from "constants/options";
import { TAccount, TPerson, TProject } from "types";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import {
  FiltersBlock,
  FiltersButton,
  FiltersContainer,
  NameInput,
  SearchButton,
  SearchRow
} from "./SearchForm.styles";
import {
  FilterOutlined,
  SearchOutlined,
  SwapOutlined
} from "@ant-design/icons";
import {
  ApplicationsTableSettings,
  TypographyTitleNoMargin
} from "components/common/redesign";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";

type TSearchFormProps = {
  onSearch: (value?: string) => void;
  isLoading: boolean;
  projects?: TProject[];
  users?: TPerson[];
  accounts?: TAccount[];
  onSortChange: () => void;
};

type TSearchFormValues = {
  statusId: number;
  projectId: number;
  creatorId: number;
  responsibleId: number;
  accountId: number;
  id: number;
  creationDt: [Dayjs, Dayjs];
  deliveryDueDt: [Dayjs, Dayjs];
};

export const SearchForm: FC<TSearchFormProps> = ({
  onSearch,
  isLoading,
  projects,
  users,
  accounts,
  onSortChange
}) => {
  const isLG = useMediaQuery({ query: DEVICE.LG });
  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [form] = Form.useForm<TSearchFormValues>();

  const [name, setName] = useState<string | undefined>(undefined);

  const hasFilters = !!Object.values(form.getFieldsValue())?.filter((i) => i)
    ?.length;

  const projectsOptions = useMemo(
    () =>
      (projects || []).map((project) => ({
        label: project?.project_name,
        value: project?.project_id
      })),
    [projects]
  );
  const usersOptions = useMemo(
    () =>
      (users || []).map((user) => ({
        label: user?.full_name,
        value: user?.user_id
      })),
    [users]
  );
  const accountsOptions = useMemo(
    () =>
      (accounts || []).map((account) => ({
        label: account?.name,
        value: account?.account_id
      })),
    [accounts]
  );

  const onNameChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setName(e?.currentTarget?.value);
  }, []);

  const onSearchHandler = useCallback(() => {
    const {
      statusId,
      id,
      projectId,
      creatorId,
      responsibleId,
      accountId,
      creationDt,
      deliveryDueDt
    } = form.getFieldsValue();

    onSearch(
      [
        name ? `name:${name}` : undefined,
        statusId ? `status.id:${statusId}` : undefined,
        id ? `id:${id}` : undefined,

        projectId ? `project.id:${projectId}` : undefined,
        accountId ? `account.id:${accountId}` : undefined,

        creatorId ? `creator.id:${creatorId}` : undefined,
        responsibleId ? `responsible.id:${responsibleId}` : undefined,

        creationDt
          ? [
              `creationDt>${creationDt[0].startOf("day").valueOf()}`,
              `creationDt<${creationDt[1].endOf("day").valueOf()}`
            ].join(",")
          : undefined,
        deliveryDueDt
          ? [
              `deliveryDueDt>${deliveryDueDt[0].startOf("day").valueOf()}`,
              `deliveryDueDt<${deliveryDueDt[1].endOf("day").valueOf()}`
            ].join(",")
          : undefined
      ]
        .filter((item) => !!item)
        .join(",")
    );
  }, [form, name, onSearch]);

  const onClearForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  return (
    <>
      <SearchRow>
        <NameInput
          value={name}
          onChange={onNameChange}
          allowClear
          placeholder="Поиск заявки"
          prefix={<SearchOutlined />}
          size="large"
        />
        <FiltersBlock>
          <SearchButton
            loading={isLoading}
            form="applications_for_buyer_search"
            onClick={onSearchHandler}
            size="large"
          >
            Поиск
          </SearchButton>
          <Popover
            trigger="click"
            placement="bottomRight"
            arrow={false}
            content={
              <FiltersContainer>
                <TypographyTitleNoMargin level={5}>
                  Фильтры
                </TypographyTitleNoMargin>
                <Form
                  name="applications_for_buyer_search"
                  form={form}
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 28 }}
                  // onFinish={onSearchHandler}
                  // onFinishFailed={onFinishFailed}
                  // autoComplete="off"
                  labelWrap
                  // colon={false}
                  layout="vertical"
                  className="withRows"
                  size="large"
                >
                  <Row gutter={isSM ? 12 : 24} justify="space-between">
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Статус"
                        name="statusId"
                        layout="vertical"
                      >
                        <Select
                          options={applicationStatusIdOptions}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="ID"
                        name="id"
                        rules={[
                          {
                            pattern: new RegExp(/^\d{1,}$/),
                            message: "Номер заявки должен состоять из цифр"
                          }
                        ]}
                      >
                        <Input allowClear />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Проект"
                        name="projectId"
                      >
                        <Select options={projectsOptions} allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Счет"
                        name="accountId"
                      >
                        <Select options={accountsOptions} allowClear />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Создатель"
                        name="creatorId"
                      >
                        <Select options={usersOptions} allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Ответственный"
                        name="responsibleId"
                      >
                        <Select options={usersOptions} allowClear />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Дата создания"
                        name="creationDt"
                      >
                        <DatePicker.RangePicker
                          format={DATE_FORMAT}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={isSM ? 24 : 12}>
                      <Form.Item<TSearchFormValues>
                        label="Дата поставки"
                        name="deliveryDueDt"
                      >
                        <DatePicker.RangePicker
                          format={DATE_FORMAT}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : undefined}>
                      <Form.Item>
                        <Button
                          type="text"
                          style={{ color: "#BFBFBF", width: "100%" }}
                          loading={isLoading}
                          form="applications_for_buyer_search"
                          onClick={onClearForm}
                        >
                          Очистить все
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col span={isSM ? 24 : 8}>
                      <Form.Item>
                        <Button
                          type="primary"
                          style={{ width: "100%" }}
                          loading={isLoading}
                          form="applications_for_buyer_search"
                          onClick={onSearchHandler}
                        >
                          Применить
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </FiltersContainer>
            }
          >
            <Badge dot={hasFilters}>
              <FiltersButton icon={<FilterOutlined />} size="large">
                {isLG ? undefined : "Фильтры"}
              </FiltersButton>
            </Badge>
          </Popover>

          <Button
            size="large"
            onClick={onSortChange}
            icon={<SwapOutlined rotate={90} />}
          />

          <ApplicationsTableSettings />
        </FiltersBlock>
      </SearchRow>
    </>
  );
};
