import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Wrapper = styled.div<{
  $hide: boolean;
  $narrowList: boolean;
  $fullWidth: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 270px;
  overflow: auto;
  border-right: 1px solid rgba(5, 5, 5, 0.06);

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}
  ${({ $narrowList }) =>
    $narrowList &&
    css`
      width: unset;
    `}
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
      border-right: none;
    `}

  & .ant-list-item {
    padding-right: 12px;
    padding-left: 4px;

    &.active {
      background-color: rgba(5, 5, 5, 0.06) !important;
    }
    &:hover {
      background: #fafafa;
      cursor: pointer;
    }
  }

  & .ant-list-item-meta-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ChatTitle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;
export const TitleCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
