import React, { FC, useMemo } from "react";

import { Button, Form, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { entityRoleIdOptions } from "constants/options";
import { TPersonEntity } from "types";
import {
  EntityFormItemStyled,
  EntityRow,
  RoleFormItemStyled
} from "./EntitiesSelector.styles";
import { TypographyTitleNoMargin } from "components/common/redesign";

type TEntitiesSelectorProps = {
  entities: TPersonEntity[];
};

export const EntitiesSelector: FC<TEntitiesSelectorProps> = ({ entities }) => {
  const entitiesOptions = useMemo(
    () =>
      entities?.map((entity) => ({
        label: entity?.entity?.short_name,
        value: entity?.entity?.entity_id
      })),
    [entities]
  );

  return (
    <>
      <Form.Item>
        <TypographyTitleNoMargin level={5}>Юр. лица</TypographyTitleNoMargin>
      </Form.Item>

      <Form.List name="entities">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <EntityRow key={key}>
                  <EntityFormItemStyled
                    {...restField}
                    name={[name, "entity"]}
                    rules={[
                      {
                        required: true,
                        message: "Выберите юр. лицо"
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (
                              getFieldValue("entities") as {
                                entity: string;
                                role_id: string;
                              }[]
                            ).filter((item) => item.entity === value).length !==
                            1
                          ) {
                            return Promise.reject(
                              new Error("Юр. лицо выбрано 2 раза")
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}
                  >
                    <Select options={entitiesOptions} />
                  </EntityFormItemStyled>
                  <RoleFormItemStyled
                    {...restField}
                    name={[name, "role_id"]}
                    rules={[
                      {
                        required: true,
                        message: "Выберите роль"
                      }
                    ]}
                  >
                    <Select options={entityRoleIdOptions} />
                  </RoleFormItemStyled>
                  <Button
                    icon={<MinusOutlined />}
                    onClick={() => remove(name)}
                  />
                </EntityRow>
              ))}
              <Form.Item>
                <Button icon={<PlusOutlined />} onClick={() => add()} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
