export type TFileRequest = {
  file_category_id: number; // идентификатор бизнес-категории
  linked_object_id: number; // ид объекта, к которому приложен файл
  linked_object_type_id: number; // ид типа объекта, к которому приложен файл
  // file_extension_id: number; // ид расширения файла
  // file_name: string; // имя файла
  file_data: File; // сам файл
};
export type TFileMeta = {
  file_meta_id: number; // идентификатор мета-данных
  file_data_id: number; // идентификатор файла
  file_category_id: number; // идентификатор бизнес-категории
  linked_object_id: number; // ид объекта, к которому приложен файл
  linked_object_type: number; // ид типа объекта, к которому приложен файл
  file_extension: number; // ид расширения файла
  creator_id: number; // ид пользователя-создателя файла
  file_name: string; // имя файла
  file_size: number; // размер файла в байтах
  created_dt: string; // дата и время добавления файла
  created_ts: number; // дата и время добавления файла
};

export enum EFileType {
  APPLICATION = 134,
  OFFER = 135
}
