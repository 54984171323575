import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationsTableSettingsActions } from "./applicationsTable";
import { offersTableSettingsActions } from "./offersTable";

export const clearSettings = createAsyncThunk<void, void>(
  "settings/clearSettings",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(applicationsTableSettingsActions.clearState());
    dispatch(offersTableSettingsActions.clearState());
  }
);
