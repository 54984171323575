import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  applicationAddActions,
  applicationAddSelectors
} from "store/applications/add";
import { addApplication } from "store/applications/add/thunk";
import {
  applicationsGetActions,
  applicationsGetSelectors
} from "store/applications/get";
import { getApplications } from "store/applications/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import { TApplication } from "types";
import {
  ApplicationEditForm,
  ApplicationsTable,
  BasicTitle
} from "components/common/redesign";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { PAGE_SIZES } from "constants/pagination";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { SearchForm } from "./components";
import { Button } from "antd";
import { Wrapper, Header } from "../../Applications.styles";
import { locationsByCabinetIdSelectors } from "store/locations/byCabinet";
import { getLocationsByCabinetId } from "store/locations/byCabinet/thunk";

export const ApplicationsForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const {
    applications,
    totalElements,
    page,
    size,
    asc,
    isLoading: applicationsLoading
  } = useAppSelector(applicationsGetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    applicationAddSelectors.getState
  );
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { locations } = useAppSelector(locationsByCabinetIdSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);

  const getApplicationsArr = useCallback(() => {
    dispatch(getApplications());
  }, [dispatch]);

  const getProjects = useCallback(
    (cabinet_id: number) => {
      dispatch(getProjectsByCabinet(cabinet_id));
    },
    [dispatch]
  );
  const getLocations = useCallback(
    (cabinet_id: number) => {
      dispatch(getLocationsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const getColleagues = useCallback(
    (cabinet_id: number) => {
      dispatch(getColleaguesByCabinet(cabinet_id));
    },
    [dispatch]
  );
  const getAccounts = useCallback(
    (cabinet_id: number) => {
      dispatch(getAccountsByCabinetId(cabinet_id));
    },
    [dispatch]
  );

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(applicationsGetActions.setPage(1));
      dispatch(applicationsGetActions.setSearch(search));
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddApplication = useCallback(
    (values: TApplication) => {
      dispatch(addApplication(values))
        .unwrap()
        .then((application) => {
          onCancelAdd();
          navigate(`${application?.application_id}`);
        });
    },
    [dispatch, navigate, onCancelAdd]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(applicationsGetActions.setPage(page));
      } else {
        dispatch(applicationsGetActions.setPage(1));
        dispatch(applicationsGetActions.setSize(pageSize));
      }
      getApplicationsArr();
    },
    [dispatch, getApplicationsArr, size]
  );
  const onSortChange = useCallback(() => {
    dispatch(applicationsGetActions.setPage(1));
    dispatch(applicationsGetActions.setAsc(!asc));
    getApplicationsArr();
  }, [asc, dispatch, getApplicationsArr]);

  useEffect(() => {
    getApplicationsArr();
  }, [getApplicationsArr]);

  useEffect(() => {
    !projects && user && getProjects(user?.cabinet?.cabinet_id);
  }, [getProjects, projects, user]);
  useEffect(() => {
    !locations && user && getLocations(user?.cabinet?.cabinet_id);
  }, [getLocations, locations, user]);
  useEffect(() => {
    !users && user && getColleagues(user?.cabinet?.cabinet_id);
  }, [getColleagues, user, users]);
  useEffect(() => {
    !accounts && user && getAccounts(user?.cabinet?.cabinet_id);
  }, [accounts, getAccounts, user]);

  useEffect(() => {
    return () => {
      dispatch(applicationsGetActions.clearState());
      dispatch(applicationAddActions.clearState());
    };
  }, [dispatch]);

  return isAdding ? (
    <ApplicationEditForm
      user={user}
      users={users}
      projects={projects}
      locations={locations}
      accounts={accounts}
      entities={user?.entities}
      onSubmit={onAddApplication}
      isLoading={addIsLoading}
      error={addError}
      onCancel={onCancelAdd}
    />
  ) : (
    <Wrapper>
      <BasicTitle>Заявки</BasicTitle>

      <Header>
        <SearchForm
          onSearch={onSearch}
          isLoading={applicationsLoading}
          projects={projects}
          users={users}
          accounts={accounts}
          onSortChange={onSortChange}
        />
        <Button size="large" type="primary" onClick={onAdd}>
          Создать заявку
        </Button>
      </Header>

      <ApplicationsTable
        user={user}
        applications={applications}
        projects={projects}
        entities={user?.entities}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </Wrapper>
  );
};
