import React, { FC, useCallback, useEffect, useState } from "react";
import { TMapProps } from "../../Map";
import { LatLngTuple, LeafletMouseEvent } from "leaflet";
import { Marker, useMap, useMapEvents } from "react-leaflet";
import { DEFAULT_PREVIEW_ZOOM } from "../../constants";

type TMapComponentProps = TMapProps;

export const MapComponent: FC<TMapComponentProps> = ({
  lat,
  lng,
  onMarkerChange
}) => {
  const [defPosition] = useState(
    lat !== undefined && lng !== undefined
      ? ([Number(lat), Number(lng)] as LatLngTuple)
      : undefined
  );

  const map = useMap();

  const handleMapClick = useCallback(
    (event: LeafletMouseEvent) => {
      const { lat, lng } = event.latlng.wrap();

      onMarkerChange?.(lat, lng);
    },
    [onMarkerChange]
  );

  useMapEvents({
    click: handleMapClick
  });

  useEffect(() => {
    if (!defPosition) {
      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.setView([latitude, longitude], DEFAULT_PREVIEW_ZOOM);
        },
        () => {
          console.error("Unable to retrieve your location.");
        }
      );
    } else {
      map.setView(defPosition, DEFAULT_PREVIEW_ZOOM);
    }
  }, [defPosition, map]);

  useEffect(() => {
    if (lat !== undefined && lng !== undefined) {
      map.flyTo([Number(lat), Number(lng)]);
    }
  }, [lat, lng, map]);

  return (
    <>
      {!!(lat !== undefined && lng !== undefined) && (
        <Marker position={[Number(lat), Number(lng)]}></Marker>
      )}
    </>
  );
};
