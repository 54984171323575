import { createAsyncThunk } from "@reduxjs/toolkit";
import { locationsByCabinetIdActions } from ".";
import * as api from "api/locations";
import { RequestError } from "types";
import { serializeError } from "serialize-error";

export const getLocationsByCabinetId = createAsyncThunk<
  api.TByCabinetIdLocationsResponse,
  api.TByCabinetIdLocationsRequest
>(
  "location/getLocationsByCabinetId",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(locationsByCabinetIdActions.setLoading(true));
    dispatch(locationsByCabinetIdActions.setError(null));
    return api
      .getLocationsByCabinetId(payload)
      .then(({ data }) => {
        dispatch(locationsByCabinetIdActions.setLocations(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(locationsByCabinetIdActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(locationsByCabinetIdActions.setLoading(false));
      });
  }
);
