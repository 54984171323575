import React, { FC } from "react";
import { Panel } from "..";
import { Button, Form, Input, Radio } from "antd";
import { RequestError, TCabinet } from "types";
import { ErrorAlert } from "..";

type TCabinetEditFormProps = {
  cabinet?: TCabinet;
  onSubmit: (values: TCabinet) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const CabinetEditForm: FC<TCabinetEditFormProps> = ({
  cabinet,
  onSubmit,
  isLoading,
  error
}) => {
  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={cabinet}
      >
        <Form.Item<TCabinet>
          label="Имя"
          name="name"
          rules={[{ required: true, whitespace: true, message: "Введите имя" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Сторона"
          name="is_seller"
          rules={[{ required: true, message: "Укажите сторону" }]}
        >
          <Radio.Group>
            <Radio value={true}>Поставщик</Radio>
            <Radio value={false}>Покупатель</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
