import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "constants/dates";
import { fromIsoDateTime } from "./fromIsoDateTime";

export const toDisplayDateTime = (date?: string | Date | number) => {
  if (!date) {
    return undefined;
  }

  const dDate = typeof date === "string" ? fromIsoDateTime(date) : dayjs(date);

  return dDate?.format(DATE_TIME_FORMAT);
};
