import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationsGetActions, applicationsGetSelectors } from ".";
import * as api from "api/applications";
import { RequestError } from "types";
import { serializeError } from "serialize-error";
import { RootState } from "../../store";

export const getApplications = createAsyncThunk<
  api.TGetApplicationsResponse,
  void,
  { state: RootState }
>(
  "application/getApplications",
  (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(applicationsGetActions.setLoading(true));
    dispatch(applicationsGetActions.setError(null));

    const { page, size, search, asc } =
      applicationsGetSelectors.getState(getState());

    return api
      .getApplications({
        search,
        page: page - 1,
        size,
        asc
      })
      .then(({ data }) => {
        dispatch(applicationsGetActions.setApplications(data?.content));
        dispatch(applicationsGetActions.setTotalElements(data?.totalElements));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(applicationsGetActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(applicationsGetActions.setLoading(false));
      });
  }
);
