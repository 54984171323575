import React, { FC, useMemo } from "react";
import { ChatTitle, TitleCol, Wrapper } from "./ChatsList.styles";
import { TChat } from "types";
import { Avatar, List, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { toDisplayMessageDateTime } from "utils/dates";
import { DEVICE } from "constants/breakpoints";
import { useMediaQuery } from "react-responsive";

type TChatsListProps = {
  chats?: TChat[];
  curChatId?: number;
  forSeller?: boolean;
  fromApplication?: boolean;
};

export const ChatsList: FC<TChatsListProps> = ({
  chats,
  curChatId,
  fromApplication,
  forSeller
}) => {
  const navigate = useNavigate();

  const isSM = useMediaQuery({ query: DEVICE.SM });
  const isMD = useMediaQuery({ query: DEVICE.MD });

  const sortedChats = useMemo(
    () =>
      [...(chats || [])]?.sort((a, b) => {
        const messageA = a?.messages?.[0];
        const messageB = b?.messages?.[0];

        if (messageA && !messageB) {
          return -1;
        } else if (!messageA && messageB) {
          return 1;
        } else if (messageA && messageB) {
          return (messageB?.sentAtTs || 0) - (messageA?.sentAtTs || 0);
        } else {
          return (b?.createdTs || 0) - (a?.createdTs || 0);
        }
      }),
    [chats]
  );

  const hideList = useMemo(() => isSM && !!curChatId, [curChatId, isSM]);
  const narrowList = useMemo(() => isMD && !!curChatId, [curChatId, isMD]);
  const fullWidth = useMemo(() => isMD && !curChatId, [curChatId, isMD]);

  return (
    <Wrapper $hide={hideList} $narrowList={narrowList} $fullWidth={fullWidth}>
      {sortedChats && (
        <List
          itemLayout="horizontal"
          dataSource={sortedChats}
          renderItem={(item) => (
            <List.Item
              className={item?.id === curChatId ? "active" : undefined}
              key={item?.id}
              onClick={() =>
                navigate(`${item?.applicationId}/${item?.sellerCabinetId}`)
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar>
                    {forSeller
                      ? item?.applicationId
                      : fromApplication
                        ? item?.sellerCabinetId
                        : `${item?.applicationId}/${item?.sellerCabinetId}`}
                  </Avatar>
                }
                title={
                  !narrowList ? (
                    <ChatTitle>
                      <TitleCol>
                        {forSeller ? (
                          <Typography.Text>
                            Заявка № {item?.applicationId}
                          </Typography.Text>
                        ) : fromApplication ? (
                          <Typography.Text>
                            {item?.sellerCabinetName}
                          </Typography.Text>
                        ) : (
                          <>
                            <Typography.Text>
                              Заявка № {item?.applicationId}
                            </Typography.Text>
                            <Typography.Text>
                              {item?.sellerCabinetName}
                            </Typography.Text>
                          </>
                        )}
                      </TitleCol>

                      <Typography.Text type="secondary">
                        <small>
                          {toDisplayMessageDateTime(
                            item?.messages[0]?.sentAtTs
                          )}
                        </small>
                      </Typography.Text>
                    </ChatTitle>
                  ) : undefined
                }
                description={
                  !narrowList
                    ? item?.messages[0] &&
                      `${item?.messages[0]?.senderName?.split(" ")[0]}: ${item?.messages[0]?.message}`
                    : undefined
                }
              />
            </List.Item>
          )}
        />
      )}
    </Wrapper>
  );
};
