import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Alert, Button } from "antd";
import { userSelectors } from "store/user";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { TPerson } from "types";
import { updateColleague } from "store/colleagues/update/thunk";
import { deleteColleague } from "store/colleagues/delete/thunk";
import { ProfileBlock } from "./PersonalTab.styles";
import { LogoutOutlined } from "@ant-design/icons";
import { getUser } from "store/user/thunk";
import { logoutActions, logoutSelectors } from "store/user/logout";
import {
  restorePasswordActions,
  restorePasswordSelectors
} from "store/user/restorePassword";
import { logout } from "store/user/logout/thunk";
import { restorePassword } from "store/user/restorePassword/thunk";
import { useMediaQuery } from "react-responsive";
import { DEVICE } from "constants/breakpoints";
import {
  BasicTitle,
  ProfileAvatar,
  UserData,
  UserEditForm
} from "components/common/redesign";
import { Footer, Header, Wrapper } from "../../Profile.styles";

export const PersonalTab = () => {
  const dispatch = useAppDispatch();

  const isSM = useMediaQuery({ query: DEVICE.SM });

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const {
    isLoading: restoreIsLoading,
    error: restoreError,
    email: restoreEmail
  } = useAppSelector(restorePasswordSelectors.getState);
  const { isLoading: logoutIsLoading, error: logoutError } = useAppSelector(
    logoutSelectors.getState
  );

  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    colleagueDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );

  const canUpdateDelete = useMemo(
    () => user?.cabinet_role_id === 2 || user?.is_admin,
    [user?.cabinet_role_id, user?.is_admin]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onChangePass = useCallback(() => {
    dispatch(restorePassword({ email: user?.email || "" }));
  }, [dispatch, user]);

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onUpdate = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...user, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          dispatch(getUser());
        });
    },
    [dispatch, onCancelEdit, user]
  );

  const onDelete = useCallback(() => {
    return (
      user &&
      dispatch(deleteColleague(user?.user_id))
        .unwrap()
        .then(() => {
          dispatch(logout({ withoutRequest: true }));
        })
    );
  }, [dispatch, user]);

  useEffect(() => {
    return () => {
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
      dispatch(logoutActions.clearState());
      dispatch(restorePasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {user ? (
        isEditing ? (
          <UserEditForm
            user={user}
            entities={user?.entities}
            onCancelEdit={onCancelEdit}
            onSubmit={onUpdate}
            submitIsLoading={updateIsLoading}
            submitError={updateError}
            onDelete={onDelete}
            deleteIsLoading={deleteIsLoading}
            deleteError={deleteError}
          />
        ) : (
          <>
            <Header>
              <ProfileBlock>
                <ProfileAvatar
                  size={isSM ? 40 : 64}
                  shape="square"
                  isSeller={!!user?.cabinet?.is_seller}
                >
                  {user?.last_name?.[0]}
                  {user?.first_name?.[0]}
                </ProfileAvatar>
                <BasicTitle>{user?.full_name}</BasicTitle>
              </ProfileBlock>

              <Button onClick={onChangePass} loading={restoreIsLoading}>
                Изменить пароль
              </Button>
            </Header>

            {/* TODO до смены ручки смены пароля */}
            {restoreEmail && (
              <Alert
                message="Проверьте Ваш почтовый ящик"
                description={`На Вашу почту ${restoreEmail} отправлено письмо с инструкцией по восстановлению пароля.`}
                type="success"
                showIcon
              />
            )}
            <ErrorAlert error={restoreError} />

            <UserData
              user={user}
              onEdit={onEdit}
              withEdit={!!canUpdateDelete}
            />

            <Footer>
              <Button
                onClick={onLogout}
                loading={logoutIsLoading}
                icon={<LogoutOutlined />}
              >
                Выйти
              </Button>
            </Footer>
            <ErrorAlert error={logoutError} />
          </>
        )
      ) : (
        <Alert message="Данные пользователя отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
