import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOffersTableSettingsState } from "./types";
import { RequestError, TOffersTableSettings } from "types";

const initialState: TOffersTableSettingsState = {
  isLoading: false,
  error: null,
  settings: {}
};

export const OffersTableSettingsSlice = createSlice({
  name: "settings/offersTable",
  initialState,
  reducers: {
    clearState: () => initialState,
    setSettings: (state, { payload }: PayloadAction<TOffersTableSettings>) => {
      state.settings = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const offersTableSettingsActions = OffersTableSettingsSlice.actions;
export const offersTableSettingsReducer = OffersTableSettingsSlice.reducer;

export * as offersTableSettingsSelectors from "./selectors";
