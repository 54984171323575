import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLocationByProjectIdState } from "./types";

import { RequestError, TLocation } from "types";

const initialState: TLocationByProjectIdState = {
  isLoading: false,
  error: null,
  location: undefined
};

export const LocationByProjectIdSlice = createSlice({
  name: "location/byProjectId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLocation: (state, { payload }: PayloadAction<TLocation>) => {
      state.location = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const locationByProjectIdActions = LocationByProjectIdSlice.actions;
export const locationByProjectIdReducer = LocationByProjectIdSlice.reducer;

export * as locationByProjectIdSelectors from "./selectors";
