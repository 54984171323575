import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLocationAddState } from "./types";
import { RequestError, TLocation } from "types";

const initialState: TLocationAddState = {
  isLoading: false,
  error: null,
  location: undefined
};

export const LocationAddSlice = createSlice({
  name: "location/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLocation: (state, { payload }: PayloadAction<TLocation>) => {
      state.location = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const locationAddActions = LocationAddSlice.actions;
export const locationAddReducer = LocationAddSlice.reducer;

export * as locationAddSelectors from "./selectors";
