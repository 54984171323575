import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLocationsByCabinetIdState } from "./types";

import { RequestError, TLocation } from "types";

const initialState: TLocationsByCabinetIdState = {
  isLoading: false,
  error: null,
  locations: undefined
};

export const LocationsByCabinetIdSlice = createSlice({
  name: "location/byCabinetId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLocations: (state, { payload }: PayloadAction<TLocation[]>) => {
      state.locations = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const locationsByCabinetIdActions = LocationsByCabinetIdSlice.actions;
export const locationsByCabinetIdReducer = LocationsByCabinetIdSlice.reducer;

export * as locationsByCabinetIdSelectors from "./selectors";
