import { TOffersTableSettings } from "types";

export const defaultOffersTableSettings: TOffersTableSettings = {
  offer_name: true,
  application_name: true,
  status_id: true,
  seller_entity_name: true,
  creator_name: true,
  positions_length: true,
  price: true,
  create_ts: false,
  update_ts: false,
  delivary_plan_ts: false,
  offer_region_id: false,
  delivery: false,
  postpayment: false,
  offer_comment: true,
  can_message: true
};
