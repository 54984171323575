import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardsGrid } from "./Colleagues.styles";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Card } from "antd";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import {
  colleagueAddActions,
  colleagueAddSelectors
} from "store/colleagues/add";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { TPerson } from "types";
import { addColleague } from "store/colleagues/add/thunk";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { deleteColleague } from "store/colleagues/delete/thunk";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import {
  BasicTitle,
  ConfirmDelete,
  ProfileAvatar,
  UserEditForm
} from "components/common/redesign";
import { Header, Wrapper } from "../../../../Profile.styles";

export const Colleagues = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    colleagueAddSelectors.getState
  );
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    colleagueDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [deletingUser, setDeletingUser] = useState<TPerson | undefined>(
    undefined
  );

  const canAddDelete = useMemo(
    () => user?.cabinet_role_id === 2 || user?.is_admin,
    [user]
  );

  const showModal = useCallback((user: TPerson) => {
    setDeletingUser(user);
  }, []);

  const closeModal = () => {
    setDeletingUser(undefined);
  };

  const getColleagues = useCallback(
    (cabinet_id: number) => {
      dispatch(getColleaguesByCabinet(cabinet_id));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);
  const onAddColleague = useCallback(
    (values: TPerson) => {
      dispatch(
        addColleague({
          ...values,
          cabinet: user!.cabinet,
          cabinet_role_id: 1
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          user && getColleagues(user?.cabinet?.cabinet_id);
        });
    },
    [dispatch, getColleagues, onCancelAdd, user]
  );

  const onDelete = useCallback(() => {
    return (
      deletingUser &&
      dispatch(deleteColleague(deletingUser?.user_id))
        .unwrap()
        .then(() => {
          user && getColleagues(user?.cabinet?.cabinet_id);
          closeModal();
        })
    );
  }, [deletingUser, dispatch, getColleagues, user]);

  useEffect(() => {
    !users && user && getColleagues(user?.cabinet?.cabinet_id);
  }, [getColleagues, user, users]);

  useEffect(() => {
    return () => {
      dispatch(colleagueAddActions.clearState());
      dispatch(colleagueDeleteActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      {isAdding ? (
        <UserEditForm
          entities={user?.entities}
          onCancelEdit={onCancelAdd}
          onSubmit={onAddColleague}
          submitIsLoading={addIsLoading}
          submitError={addError}
        />
      ) : (
        <>
          <Header>
            <BasicTitle>{user?.cabinet?.name}</BasicTitle>

            {canAddDelete && <Button onClick={onAdd}>Добавить коллегу</Button>}
          </Header>

          <CardsGrid>
            {(users || []).map((colleague) => (
              <Card
                actions={[
                  <Link to={`${colleague?.user_id}`}>
                    <UserOutlined />
                  </Link>,
                  ...(canAddDelete && colleague?.user_id !== user?.user_id
                    ? [
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => showModal(colleague)}
                        />
                      ]
                    : [])
                ]}
              >
                <Card.Meta
                  avatar={
                    <ProfileAvatar
                      size={32}
                      shape="square"
                      isSeller={!!user?.cabinet?.is_seller}
                    >
                      {colleague?.last_name?.[0]}
                      {colleague?.first_name?.[0]}
                    </ProfileAvatar>
                  }
                  title={`${colleague?.last_name} ${colleague?.first_name}`}
                  description={colleague?.position}
                />
              </Card>
            ))}
          </CardsGrid>
          <ConfirmDelete
            open={!!deletingUser}
            onClose={closeModal}
            onDelete={onDelete}
            isLoading={deleteIsLoading}
            error={deleteError}
            title={`Удалить коллегу ${deletingUser?.last_name} ${deletingUser?.first_name}?`}
            subtitle="Вы уверены, что хотите удалить этого коллегу из вашего личного кабинета? У него больше не будет доступа к совместным данным и проектам. Подтвердить удаление?"
          />
        </>
      )}
    </Wrapper>
  );
};
