import React, { FC, useEffect, useMemo } from "react";
import { TEntity, TLocation } from "types";
import { DescriptionsProps } from "antd";
import { DescriptionsCustom } from "../DescriptionsCustom/DescriptionsCustom";
import { Map } from "../Map/Map";

type TLocationDataProps = {
  location: TLocation;
  entity?: TEntity;
  vertical?: boolean;
  setDataForCopy?: (value: string) => void;
};

export const LocationData: FC<TLocationDataProps> = ({
  location,
  entity,
  vertical,
  setDataForCopy
}) => {
  // const onPreview = useCallback(() => {
  //   window.open(
  //     `https://yandex.ru/maps/?pt=${location?.longitude},${location?.latitude}&z=14`,
  //     "_blank"
  //   );
  // }, [location]);

  const generalData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "title",
        label: "Название",
        children: location?.title
      },
      {
        key: "address",
        label: "Адрес",
        children: location?.address
      },
      {
        key: "entity",
        label: "Юр. лицо",
        children: entity?.full_name
      },
      {
        key: "latitude",
        label: "Широта",
        children: location?.latitude
      },
      {
        key: "longitude",
        label: "Долгота",
        children: location?.longitude
      },
      {
        key: "preview",
        label: "Посмотреть на карте",
        children: !!(
          location?.latitude !== undefined && location?.longitude !== undefined
        ) && (
          <Map
            lat={location?.latitude}
            lng={location?.longitude}
            width="300px"
          />
        )
      }
    ],
    [location, entity]
  );

  useEffect(() => {
    if (setDataForCopy) {
      setDataForCopy(
        generalData
          .map((item) => `${item?.label}: ${item?.children || "-"}`)
          .join("\n")
      );
    }
  }, [generalData, setDataForCopy]);

  return <DescriptionsCustom items={generalData} vertical={vertical} />;
};
