import React, { FC, useMemo } from "react";
import { TEntity, TLocation, TProject } from "types";
import { DescriptionsProps } from "antd";
import { projectRegionIdMapper } from "utils/mappers";
import { DescriptionsCustom } from "../DescriptionsCustom/DescriptionsCustom";

type TProjectDataProps = {
  project: TProject;
  entity?: TEntity;
  locations?: TLocation[];
};

export const ProjectData: FC<TProjectDataProps> = ({
  project,
  entity,
  locations
}) => {
  const generalData: DescriptionsProps["items"] = useMemo(
    () => [
      {
        key: "project_name",
        label: "Название",
        children: project?.project_name
      },
      {
        key: "project_description",
        label: "Описание",
        children: project?.project_description
      },
      {
        key: "entity",
        label: "Юр. лицо",
        children: entity?.full_name
      },
      {
        key: "location",
        label: "Адрес доставки",
        children: locations?.find(
          (location) => location?.location_id === project?.location_id
        )?.address
      },
      {
        key: "delivery_comment",
        label: "Комментарий для доставки",
        children: project?.delivery_comment
      },
      {
        key: "region",
        label: "Район",
        children:
          projectRegionIdMapper[project?.region_id]?.fullName ||
          project?.region_id
      },
      {
        key: "is_active",
        label: "Активен",
        children: project?.is_active ? "Да" : "Нет"
      }
    ],
    [entity, project, locations]
  );
  return <DescriptionsCustom items={generalData} />;
};
