import { TLocation } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const addLocation = (
  data: TAddLocationRequest
): RequestPromise<TAddLocationResponse> => {
  return apiRequest.post(`/location/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TAddLocationRequest = Omit<TLocation, "location_id">;

export type TAddLocationResponse = TLocation;
