import React, { FC, useCallback, useMemo } from "react";
import { Button, Form, Input, Select } from "antd";
import { RequestError, TLocation, TPersonEntity } from "types";
import { ErrorAlert } from "../../old";
import { CTABlock } from "./LocationEditForm.styles";
import { LeftOutlined } from "@ant-design/icons";
import { BasicTitle } from "../BasicTitle/BasicTitle";
import { LATITUDE_REGEX, LONGITUDE_REGEX } from "constants/regex";
import { Map } from "../Map/Map";

type TLocationEditFormProps = {
  location?: TLocation;
  entities?: TPersonEntity[];
  onCancelEdit: () => void;
  onSubmit: (values: TLocation) => void;
  submitIsLoading: boolean;
  submitError: RequestError | null;
};

export const LocationEditForm: FC<TLocationEditFormProps> = ({
  location,
  entities,
  onCancelEdit,
  onSubmit,
  submitIsLoading,
  submitError
}) => {
  const [form] = Form.useForm<TLocation>();

  const title = Form.useWatch("title", form);
  const latitude = Form.useWatch("latitude", form);
  const longitude = Form.useWatch("longitude", form);

  const entitiesOptions = useMemo(
    () =>
      (entities || []).map((entity) => ({
        label: entity?.entity?.full_name,
        value: entity?.entity?.entity_id
      })),
    [entities]
  );

  // const canPreview = useMemo(
  //   () => LATITUDE_REGEX.test(latitude) && LONGITUDE_REGEX.test(longitude),
  //   [latitude, longitude]
  // );

  // const onPreview = useCallback(() => {
  //   window.open(
  //     `https://yandex.ru/maps/?pt=${longitude},${latitude}&z=14`,
  //     "_blank"
  //   );
  // }, [latitude, longitude]);

  const onMarkerChange = useCallback(
    (lat: number, lng: number) => {
      form.setFieldValue("latitude", lat?.toFixed(10)?.toString());
      form.setFieldValue("longitude", lng?.toFixed(10)?.toString());
    },
    [form]
  );

  return (
    <>
      <BasicTitle>
        <Button icon={<LeftOutlined />} onClick={onCancelEdit} type="text" />
        {location ? location?.title : title}
      </BasicTitle>

      <Form
        name="location_edit"
        form={form}
        layout="vertical"
        requiredMark={false}
        style={{ maxWidth: "400px" }}
        onFinish={onSubmit}
        autoComplete="off"
        initialValues={location}
      >
        <Form.Item<TLocation>
          label="Название"
          name="title"
          rules={[
            { required: true, whitespace: true, message: "Введите название" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TLocation>
          label="Адрес"
          name="address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите адрес"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TLocation>
          label="Юр. лицо"
          name="entity_id"
          rules={[
            {
              required: true,
              message: "Введите юр. лицо"
            }
          ]}
        >
          <Select options={entitiesOptions} />
        </Form.Item>

        <Form.Item<TLocation>
          label="Широта"
          name="latitude"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Выберите точку на карте"
            },
            {
              pattern: LATITUDE_REGEX,
              message: "Введите широту в формате 0.000000"
            }
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item<TLocation>
          label="Долгота"
          name="longitude"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Выберите точку на карте"
            },
            {
              pattern: LONGITUDE_REGEX,
              message: "Введите долготу в формате 0.000000"
            }
          ]}
        >
          <Input disabled />
        </Form.Item>

        <ErrorAlert error={submitError} />
      </Form>

      <Map
        lat={latitude || location?.latitude}
        lng={longitude || location?.longitude}
        onMarkerChange={onMarkerChange}
      />

      <CTABlock>
        {/* <Button
          icon={<CompassOutlined />}
          disabled={!canPreview}
          onClick={onPreview}
        >
          Посмотреть на карте
        </Button> */}

        <Button
          type="primary"
          loading={submitIsLoading}
          form="location_edit"
          htmlType="submit"
        >
          Сохранить
        </Button>
      </CTABlock>
    </>
  );
};
