import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TLocationUpdateState } from "./types";

import { RequestError, TLocation } from "types";

const initialState: TLocationUpdateState = {
  isLoading: false,
  error: null,
  location: undefined
};

export const LocationUpdateSlice = createSlice({
  name: "location/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setLocation: (state, { payload }: PayloadAction<TLocation>) => {
      state.location = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    }
  }
});

export const locationUpdateActions = LocationUpdateSlice.actions;
export const locationUpdateReducer = LocationUpdateSlice.reducer;

export * as locationUpdateSelectors from "./selectors";
