import React, { FC, useMemo } from "react";
import { TApplicationEditFormTabProps } from "../../ApplicationEditForm";
import { CTABlock, NavButton } from "../../ApplicationEditForm.styles";
import { ErrorAlert } from "components/common/old";
import { ApplicationData } from "components/common/redesign";
import { TApplication } from "types";

export const PreviewTab: FC<TApplicationEditFormTabProps> = ({
  formData,
  projects,
  entities,
  isLoading,
  error,
  onSubmitForm,
  onPrevStep,
  onStepEdit
}) => {
  const project = useMemo(
    () =>
      projects?.find((project) => project?.project_id === formData?.project_id),
    [formData, projects]
  );

  const entity = useMemo(
    () =>
      entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      )?.entity,
    [entities, project]
  );

  return (
    <>
      <ApplicationData
        application={formData as TApplication}
        entity={entity}
        project={project}
        previewOnly
        onStepEdit={onStepEdit}
      />

      <ErrorAlert error={error} />

      <CTABlock>
        <NavButton type="primary" onClick={onSubmitForm} loading={isLoading}>
          Завершить
        </NavButton>
        <NavButton onClick={onPrevStep}>Назад</NavButton>
      </CTABlock>
    </>
  );
};
