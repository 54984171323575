import React, { FC } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { MapComponent } from "./components";
import { DEFAULT_POSITION, DEFAULT_ZOOM } from "./constants";

// Настраиваем иконки Leaflet
delete (L.Icon.Default as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export type TMapProps = {
  lat?: string;
  lng?: string;
  onMarkerChange?: (lat: number, lng: number) => void;
  height?: string;
  width?: string;
};

export const Map: FC<TMapProps> = ({
  height = "300px",
  width = "100%",
  ...props
}) => {
  return (
    <MapContainer
      center={DEFAULT_POSITION}
      zoom={DEFAULT_ZOOM}
      scrollWheelZoom={true}
      style={{ height, width, overflow: "hidden" }}
      attributionControl={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapComponent {...props} />
    </MapContainer>
  );
};
